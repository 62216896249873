import React, { PropsWithChildren, createContext, useEffect, useRef } from 'react';

import env from '@shared/config';
import { EnvType } from '@shared/config/@types/EnvTypeEnum';
import { assetService } from '@shared/services';
import { AppStore, UserStore } from '@shared/store';

import * as RudderAnalytics from 'rudder-sdk-js';

import WebAvo, { AvoEnv, CustomDestination } from './generated-avo';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = Record<string, any>;

// TODO Build out helpers here or in Avo.tsx to make it easier to use Avo in the rest of the app

// TODO Remember to update CSP for loading libraries from rudderstack not segment
const isProd = env.ENV === EnvType.PROD;

// Apply hacks to Avo to handle certain properties automatically
type MappedWebEvents = {
  [key in keyof typeof WebAvo as (typeof WebAvo)[key] extends AnyFunction ? key : never]: (typeof WebAvo)[key];
};

type WebEvents = Omit<MappedWebEvents, 'initAvo' | 'setSystemProperties'>;

export type WebEventsWithHacks = {
  [K in keyof WebEvents]: (
    properties: Omit<Parameters<WebEvents[K]>[0], 'baseCurrencyId' | 'baseCurrencyCode' | 'isDemoMode'>,
  ) => ReturnType<WebEvents[K]>;
};

// Take care of any functions that have no arguments by defaulting the parameter to an empty object
type Defaulted<T extends AnyObject | undefined> = T extends undefined ? {} : Exclude<T, undefined>;

// Get a list of all the functions that Avo has generated
const originalFunctions = Object.entries(WebAvo).filter(
  (tuple): tuple is [keyof WebEvents, WebEvents[keyof WebEvents]] =>
    typeof tuple[1] === 'function' && !['initAvo', 'setSystemProperties'].includes(tuple[0]),
);

// Create an object to store the Avo methods in
const webEvents = {} as WebEventsWithHacks;
// Override each generated method to apply any hacks we want eg auto-populate baseCurrencyId and baseCurrencyCode
for (const [key, func] of originalFunctions) {
  webEvents[key] = function (properties: Parameters<WebEventsWithHacks[typeof key]>[0]) {
    // Hack #1 - Automatically resolve values for baseCurrencyId and baseCurrencyCode
    const { userBaseCurrency } = UserStore.useUserStore;
    const baseCurrencyId = userBaseCurrency;
    const baseCurrencyCode = assetService.getAssetCodeById(userBaseCurrency);

    // Hack #2 - Automatically resolve isDemoMode
    const isDemoMode = AppStore.useAppStore.isDemo;

    const withExtra: Defaulted<Parameters<WebEvents[typeof key]>[0]> = Object.assign(properties ?? {}, {
      baseCurrencyId,
      baseCurrencyCode: baseCurrencyCode ?? 'AUD',
      isDemoMode,
    });

    // TS doesn't like that this isn't a union of all possible arguments, but it works
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return func(withExtra as any);
  };
}

type AvoContextType = {
  webAvo: WebEventsWithHacks;
  rudderAnalytics?: typeof RudderAnalytics;
};

const AvoContext = createContext<AvoContextType>({
  webAvo: webEvents,
  rudderAnalytics: undefined,
});

const AvoProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const webAvo = useRef<WebEventsWithHacks>(webEvents);
  const latestRudder = useRef<typeof RudderAnalytics>();

  // Used to ensure that we delay events until analytics has loaded
  const onceRudderReady = (callback: (segment: typeof RudderAnalytics) => void) => {
    if (latestRudder.current) {
      callback(latestRudder.current);
    } else {
      setTimeout(() => onceRudderReady(callback), 100);
    }
  };

  // Build Rudderstack destination for Avo to use
  const rudderStackDestination: CustomDestination = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    make: function (avoEnv: AvoEnv, apiKey: string) {
      RudderAnalytics.load(env.RUDDERSTACK_WRITE_KEY ?? '', env.RUDDERSTACK_DATA_PLANE_URL ?? '');

      RudderAnalytics.ready(() => {
        latestRudder.current = RudderAnalytics;
      });
    },
    logEvent: async function (eventName: string, eventProperties: RudderAnalytics.apiObject) {
      // Add call to the buffer if analytics has not finished loading yet
      onceRudderReady((rudder) => rudder.track(eventName, eventProperties));
    },
    setUserProperties: async function (userId: string, userProperties: RudderAnalytics.apiObject) {
      onceRudderReady((rudder) => rudder.identify(userId, userProperties));
    },
    identify: async function (userId: string) {
      onceRudderReady((rudder) => rudder.identify(userId));
    },
    unidentify: async function () {
      latestRudder?.current?.reset();
    },
    logPage: async function (pageName: string, eventProperties: RudderAnalytics.apiObject) {
      onceRudderReady((rudder) => rudder.page('', pageName, eventProperties)); // Empty category
    },
  };

  useEffect(() => {
    const loadAnalytics = async () => {
      await WebAvo.initAvo(
        { env: isProd ? AvoEnv.Prod : AvoEnv.Dev },
        { serviceName: `${isProd ? AvoEnv.Prod : AvoEnv.Dev}-react-web` },
        {},
        rudderStackDestination,
      );
    };

    loadAnalytics();
  }, []);

  return (
    <AvoContext.Provider value={{ webAvo: webAvo.current, rudderAnalytics: latestRudder.current }}>
      {children}
    </AvoContext.Provider>
  );
};

export { AvoProvider, AvoContext };
