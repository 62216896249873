import { useEffect, useRef } from 'react';

import env from '@shared/config';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

function useRiskified() {
  const riskifiedUrl = env.RISKIFIED_URL;
  /**
   * The SHOP is the root domain as configured by Riskified in https://app.riskified.com/app/settings/account/technical
   * Note: this is just a string and does not need to reflect to domain that the service is being called from
   */
  const shop = env.RISKIFIED_SHOP;
  const isReadyPromiseRef = useRef<Promise<void>>();
  const sidRef = useRef<string>(
    (window.crypto && 'randomUUID' in window.crypto && window.crypto.randomUUID()) || String(Date.now()),
  );
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const baseUrl = `${riskifiedUrl}?shop=${shop}`;

  useEffect(() => {
    if (!isFeatureEnabled(AppFeature.Riskified)) {
      isReadyPromiseRef.current = undefined;
      return;
    }

    if (isReadyPromiseRef.current) {
      return;
    }

    isReadyPromiseRef.current = new Promise<void>((resolve, reject) => {
      const scriptId = 'riskified-script';
      let script = document.getElementById(scriptId) as HTMLScriptElement;

      if (script) {
        resolve();
        return;
      }

      script = document.createElement('script');
      script.id = scriptId;

      const url = `${baseUrl}&sid=${sidRef.current}`;

      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject(new Error('Failed to load the Riskified script'));
      };

      script.setAttribute('data-test', 'riskified');
      script.async = true;
      script.src = url;
      document.body.appendChild(script);
    });

    return () => {
      const script = document.getElementById('riskified-script');
      if (script) {
        script.remove();
      }
      isReadyPromiseRef.current = undefined;
    };
  }, [baseUrl, isFeatureEnabled]);

  const getSid = () => sidRef.current;

  return { riskifiedReady: isReadyPromiseRef.current, getSid };
}

export { useRiskified };
