import { useCallback } from 'react';

import { UserStore } from '@shared/store';

import { useDemoMode } from 'src/lib/utils/hooks';

const useTradePriceHistory = () => {
  const { tradePriceHistory } = UserStore.useUserStore;
  const { isDemo } = useDemoMode();

  const getTradePriceHistory = useCallback(
    (id: number) => {
      if (isDemo) return undefined;

      return tradePriceHistory?.[id];
    },
    [isDemo, tradePriceHistory],
  );

  return {
    getTradePriceHistory,
  };
};

export { useTradePriceHistory };
