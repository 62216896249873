import { useMemo } from 'react';

import { Asset } from '@shared/api';
import { OfferingTier } from '@shared/api/@types/staking';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UserStore } from '@shared/store';

import { useTradePriceHistory } from 'src/lib/portfolio/hooks/useTradePriceHistory';
import { getBalanceValue } from 'src/utils/balance';

export type EarnBalance = {
  isStakeable: boolean;
  isStaking: boolean;
  expectedRewards: string;
  balance: string;
  rewards: string;
  disabled: boolean;
  value: string;
  rpy: string;
  remainingCapacity: string;
  totalCapacity: string;
  tiers: OfferingTier[];
  offerId: string;
};

export type AssetBalanceData = {
  assetValue: string;
  all: {
    averageBuyPrice: string | null;
    changePercent: string | null;
    balance: string;
    change: string | null;
    value: string;
  };
  trading: {
    averageBuyPrice: string | null;
    changePercent: string | null;
    balance: string;
    change: string | null;
    value: string;
    invested: string;
  };
  staking: EarnBalance;
};

type Options = {
  forceBaseAsset?: Asset;
};

const emptyState: AssetBalanceData = {
  assetValue: '0',
  all: {
    averageBuyPrice: null,
    changePercent: null,
    change: null,
    balance: '0',
    value: '0',
  },
  trading: {
    averageBuyPrice: null,
    changePercent: null,
    change: null,
    balance: '0',
    value: '0',
    invested: '0',
  },
  staking: {
    isStakeable: false,
    isStaking: false,
    expectedRewards: '0',
    balance: '0',
    rewards: '0',
    value: '0',
    rpy: '0',
    disabled: false,
    remainingCapacity: '0',
    totalCapacity: '0',
    tiers: [],
    offerId: '',
  },
};

// TODO convert to big?
export const useAssetBalance = (asset: Asset, options?: Options): AssetBalanceData => {
  const { getRate } = RatesStore.useRatesStore;
  const { getAsset } = assetService;
  const { getTradePriceHistory } = useTradePriceHistory();
  const { userBaseCurrency, balances, stakingInfo } = UserStore.useUserStore;
  const baseAsset = options?.forceBaseAsset ? options.forceBaseAsset : getAsset(userBaseCurrency);

  const balance = useMemo(() => balances?.[asset.id], [asset, balances]);
  const tradeHistory = getTradePriceHistory(asset.id);
  const assetStakingInfo = useMemo(() => stakingInfo?.[asset.id]?.[0], [asset, stakingInfo]);
  const assetValue = Big(getRate(asset).midPrice);

  const all = useMemo(() => {
    const allBalance = Big(getBalanceValue(balance, 'all'));
    const allValue = assetValue.times(allBalance);

    const { change, changePercent, averageBuyPrice } = (() => {
      if (tradeHistory) {
        const avgPricePaidInBase = Big(tradeHistory.avgPricePaid);
        const currentValue = assetValue.times(allBalance);
        const boughtAtValue = avgPricePaidInBase.times(allBalance);
        const multipliedBy = boughtAtValue.eq(0) ? Big(1) : currentValue.div(boughtAtValue);
        const profit = currentValue.minus(boughtAtValue);
        const percent = multipliedBy.lt(1) ? -Big(1).minus(multipliedBy).times(100) : multipliedBy.minus(1).times(100);
        return {
          change: profit.toString(),
          changePercent: percent.toString(),
          averageBuyPrice: avgPricePaidInBase.toString(),
        };
      }
      return { change: null, changePercent: null, averageBuyPrice: null };
    })();

    return { balance: allBalance.toString(), value: allValue.toString(), change, changePercent, averageBuyPrice };
  }, [asset, balance, assetValue, tradeHistory]);

  const trading = useMemo(() => {
    const tradingBalance = Big(getBalanceValue(balance, 'availableBalance'));
    const tradingValue = assetValue.times(tradingBalance);

    const { change, changePercent, averageBuyPrice } = (() => {
      if (tradeHistory) {
        const avgPricePaidInBase = Big(tradeHistory.avgPricePaid);
        const currentValue = assetValue.times(tradingBalance);
        const boughtAtValue = avgPricePaidInBase.times(tradingBalance);
        const multipliedBy = boughtAtValue.eq(0) ? Big(1) : currentValue.div(boughtAtValue);
        const profit = currentValue.minus(boughtAtValue);
        // ugly but if its decreased its just the opposite factor, if its increased its 1 minus the factor to get the growth over 100% of old value :)
        const percent = multipliedBy.lt(1) ? -Big(1).minus(multipliedBy).times(100) : multipliedBy.minus(1).times(100);
        return {
          change: profit.toString(),
          changePercent: percent.toString(),
          averageBuyPrice: avgPricePaidInBase.toString(),
        };
      }
      return { change: null, changePercent: null, averageBuyPrice: null };
    })();

    return {
      balance: tradingBalance.toString(),
      value: tradingValue.toString(),
      change,
      changePercent,
      averageBuyPrice,
      invested: '0',
    };
  }, [asset, balance, tradeHistory, assetValue]);

  const staking = useMemo(() => {
    const stakingBalance = Big(getBalanceValue(balance, 'stakingBalance'));
    const stakingValue = assetValue.times(stakingBalance);
    const stakingRPY = assetStakingInfo?.rate ? Big(assetStakingInfo.rate) : 0;
    const stakingRewards = assetStakingInfo?.rewards ? Big(assetStakingInfo.rewards) : 0;
    const expectedStakingRewards = assetStakingInfo?.expectedReward ? Big(assetStakingInfo.expectedReward) : 0;

    const remainingPool = Big(assetStakingInfo?.capacityRemaining.toString() || '0').toNumber();
    const userLimit = assetStakingInfo?.userLimit ? Big(assetStakingInfo?.userLimit).toNumber() : Big('0').toNumber();
    const userLimitMax = Math.max(0, Big(userLimit).minus(stakingBalance).toNumber());

    const remainingCapacity = userLimit ? Math.min(remainingPool, userLimitMax).toString() : remainingPool.toString();
    const totalCapacity = userLimit ? Math.min(remainingPool, userLimit).toString() : remainingPool.toString();

    // this effectively means that for an asset that's ever been staked that this will return true after the first daily payout
    // - on the first day you ever put anything in earn you'll be able to remove the full balance as there are no issues with precision
    // - after your first daily payout your earn balance will have 18 decimal places, so a 100% removal earn in the UI, which is not to 18 decimal places, will still leave a non-zero balance
    // - this will be improved in future
    const isStaking = stakingBalance.gt(0);

    return {
      isStakeable: assetStakingInfo !== undefined,
      isStaking,
      expectedRewards: expectedStakingRewards.toString(),
      balance: stakingBalance.toString() || '0',
      rewards: stakingRewards.toString(),
      value: stakingValue.toString() || '0',
      rpy: stakingRPY.toString(),
      remainingCapacity,
      totalCapacity,
      disabled: assetStakingInfo?.disabled || false,
      tiers: assetStakingInfo?.tiers || [],
      offerId: assetStakingInfo?.offerId?.toString() || '',
    };
  }, [asset, balance, assetStakingInfo, assetValue]);

  if (!baseAsset || !asset) {
    return emptyState;
  }

  return {
    all,
    trading,
    staking,
    assetValue: assetValue.toString(),
  };
};
