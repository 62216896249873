import { useMemo } from 'react';

import { Asset } from '@shared/api';
import { FiatIdEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { AppStore, RatesStore, UserStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { getTierDetails } from '@utils/earn';

import { useTradePriceHistory } from 'src/lib/portfolio/hooks/useTradePriceHistory';
import { BalanceKey, getBalanceValue } from 'src/utils/balance';

type Options = {
  balanceKey?: BalanceKey;
  forceBaseAsset?: Asset;
  hideFiat?: boolean;
};

const DEMO_MODE_INITIAL_VALUE = 10000;

// TODO: better recalculate trigger
// TODO: find out whats wrong with asset 260 not passing convertRate
export const usePortfolio = (options?: Options) => {
  const balanceKey = options?.balanceKey || 'availableBalance';
  const { balances, userCountryCurrency, userStatistics, tradePriceHistory, stakingInfo } = UserStore.useUserStore;
  const { isDemo } = AppStore.useAppStore;
  const { convertRate, getRate } = RatesStore.useRatesStore;
  const { getAsset, isAssetFiat } = assetService;
  const currentBaseAsset = useBaseAsset();
  const { getTradePriceHistory } = useTradePriceHistory();
  const baseAsset = options?.forceBaseAsset ? options.forceBaseAsset : currentBaseAsset;

  const baseAssetPriceScale = assetService.getDisplayPriceScale(baseAsset);

  const countryRate = getRate(userCountryCurrency).midPrice;
  const demoInitialValue = convertRate(
    FiatIdEnum.USD,
    baseAsset || FiatIdEnum.USD,
    DEMO_MODE_INITIAL_VALUE,
    'midPrice',
  );

  const deposited = useMemo(
    () => (isDemo ? Big(demoInitialValue) : Big(countryRate).times(userStatistics?.deposited ?? 0)),
    [countryRate, isDemo, demoInitialValue, userStatistics?.deposited],
  );
  const withdrawn = useMemo(
    () => Big(countryRate).times(userStatistics?.withdrawn ?? 0),
    [countryRate, userStatistics?.withdrawn],
  );
  const traded = useMemo(
    () => Big(countryRate).times(userStatistics?.traded ?? 0),
    [countryRate, userStatistics?.traded],
  );

  const orders = useMemo(() => userStatistics?.orders, [userStatistics]);

  const portfolio = useMemo(() => {
    let accountValue = Big(0);
    let highestPerformerAssetId: number | null = null;
    let highestPerformerProfit: Big | null = null;
    let lowestPerformerAssetId: number | null = null;
    let lowestPerformerProfit: Big | null = null;

    let stakingValueTotal: Big = Big(0);
    let stakingBalanceTotal: Big = Big(0);
    let nextPayoutTotal: Big = Big(0);
    let stakingRewardsTotal: Big = Big(0);
    let highestRewardRPY: Big | null = null;
    let highestRewardRPYAssetId: number | null = null;
    let highestStakingRewards: Big | null = null;
    let highestStakingRewardsAssetId: number | null = null;
    let lowestRewardRPY: Big | null = null;
    let lowestRewardRPYAssetId: number | null = null;
    let lowestStakingRewards: Big | null = null;
    let lowestStakingRewardsAssetId: number | null = null;

    // TODO convert to reduce
    // eslint-disable-next-line no-restricted-syntax
    for (const balance of Object.values(balances)) {
      if (!(options?.hideFiat && isAssetFiat(balance.assetId))) {
        // Staking
        const assetStakingBalance = getBalanceValue(balance, 'stakingBalance');
        const assetStakingValue = Big(getRate(balance.assetId).midPrice).times(assetStakingBalance);
        const assetStakingInfo = stakingInfo?.[balance.assetId]?.[0];
        const stakingRPY = Big(
          getTierDetails(
            assetStakingInfo?.tiers || [],
            assetStakingBalance,
            assetStakingInfo?.capacityRemaining.toString() || '0',
          ).apy,
        );
        const stakingRewards = Big(assetStakingInfo?.rewards ? parseFloat(assetStakingInfo.rewards) : 0);
        const stakingRewardsValue = Big(getRate(balance.assetId).midPrice).times(stakingRewards);
        const expectedStakingRewards = Big(
          assetStakingInfo?.expectedReward ? parseFloat(assetStakingInfo.expectedReward) : 0,
        );
        const expectedStakingRewardsValue = Big(getRate(balance.assetId).midPrice).times(expectedStakingRewards);

        stakingValueTotal = stakingValueTotal.add(assetStakingValue);
        stakingBalanceTotal = stakingBalanceTotal.add(assetStakingBalance);
        nextPayoutTotal = nextPayoutTotal.add(expectedStakingRewardsValue);
        stakingRewardsTotal = stakingRewardsTotal.add(stakingRewardsValue);

        if (
          assetStakingBalance &&
          ((stakingRewards.gt(0) && highestStakingRewards === null) ||
            stakingRewardsValue.gt(highestStakingRewards || 0))
        ) {
          highestStakingRewards = stakingRewardsValue;
          highestStakingRewardsAssetId = balance.assetId;
        }

        if (
          assetStakingInfo !== undefined &&
          Big(assetStakingBalance).gt(0) &&
          ((stakingRewards.gt(0) && lowestStakingRewards === null) ||
            (lowestStakingRewards !== null && stakingRewardsValue.lt(lowestStakingRewards)))
        ) {
          lowestStakingRewards = stakingRewardsValue;
          lowestStakingRewardsAssetId = balance.assetId;
        }

        if (Big(assetStakingBalance).gt(0) && stakingRPY.gt(0) && stakingRPY.gt(highestRewardRPY || 0)) {
          highestRewardRPY = stakingRPY;
          highestRewardRPYAssetId = balance.assetId;
        }
        if (
          Big(assetStakingBalance).gt(0) &&
          stakingRPY.gt(0) &&
          (lowestRewardRPY !== null ? stakingRPY.lt(lowestRewardRPY) : true)
        ) {
          lowestRewardRPY = stakingRPY;
          lowestRewardRPYAssetId = balance.assetId;
        }

        // Generic
        const balanceAsset = getAsset(balance.assetId);
        const assetBalance = Big(getBalanceValue(balance, balanceKey));

        if (balanceAsset && baseAsset) {
          const balanceBaseValue = convertRate(balance.assetId, baseAsset, assetBalance, 'midPrice');
          const tradeHistory = getTradePriceHistory(balance.assetId);
          const avgPricePaidInBase = tradeHistory?.avgPricePaid ? Big(parseFloat(tradeHistory.avgPricePaid)) : null;
          const boughtAtValue = avgPricePaidInBase ? avgPricePaidInBase.times(assetBalance) : null;
          const profit = boughtAtValue ? balanceBaseValue.minus(boughtAtValue) : null;

          if (profit !== null && (highestPerformerProfit === null || profit.gt(highestPerformerProfit))) {
            highestPerformerProfit = profit;
            highestPerformerAssetId = balance.assetId as number;
          }

          if (profit !== null && (lowestPerformerProfit === null || profit.lt(lowestPerformerProfit))) {
            lowestPerformerProfit = profit;
            lowestPerformerAssetId = balance.assetId as number;
          }

          const isNegative = balanceBaseValue.lt(0);
          if (!isNegative) accountValue = accountValue.plus(balanceBaseValue);
        }
      }
    }

    const profit = accountValue.plus(withdrawn).minus(deposited);
    const percentChange = deposited.gt(0) ? profit.div(deposited).times(100) : profit.times(100);
    return {
      accountValue,
      profit,
      percentChange,
      highestPerformer: {
        assetId: highestPerformerAssetId,
        profit: highestPerformerProfit,
      },
      lowestPerformer: {
        assetId: lowestPerformerAssetId,
        profit: lowestPerformerProfit,
      },
      balances,
      staking: {
        stakingValueTotal,
        stakingBalanceTotal,
        nextPayoutTotal,
        stakingRewardsTotal,
        highestRewardRPY,
        highestRewardRPYAssetId,
        highestStakingRewards,
        highestStakingRewardsAssetId,
        lowestRewardRPY,
        lowestRewardRPYAssetId,
        lowestStakingRewards,
        lowestStakingRewardsAssetId,
      },
    };
  }, [
    withdrawn,
    deposited,
    balances,
    options?.hideFiat,
    isAssetFiat,
    getRate,
    stakingInfo,
    getAsset,
    balanceKey,
    baseAsset,
    convertRate,
    tradePriceHistory,
  ]);

  const accountValueDisplay = useMemo(
    () =>
      formatCurrency(portfolio.accountValue, baseAsset, {
        hideCode: true,
        priceScale: baseAssetPriceScale,
        appendCode: false,
      }),
    [portfolio.accountValue, baseAsset, baseAssetPriceScale],
  );

  const percentageTicker = useMemo(() => {
    const isPositive = portfolio.percentChange.gte(0);
    const symbol = isPositive ? '+' : '';

    const percentage = portfolio.percentChange.toString();
    const [lhs, rhs] = percentage.split('.');
    const hasDecimal = !!rhs;
    const percentageDisplay = !hasDecimal ? `${symbol}${lhs}%` : `${symbol}${lhs}.${rhs.slice(0, 2)}%`;
    return { isPositive, percentageDisplay };
  }, [portfolio.percentChange]);

  const profitDisplay = useMemo(() => {
    const isPositive = portfolio.profit.gte(0);
    const symbol = isPositive ? '+' : '';
    const formatted = formatCurrency(portfolio.profit, baseAsset, { priceScale: baseAssetPriceScale });
    return `${symbol}${formatted}`;
  }, [portfolio.profit, baseAsset, baseAssetPriceScale]);

  const output = useMemo(
    () => ({
      accountValueDisplay,
      percentageTicker,
      profitDisplay,
      portfolio,
      deposited,
      withdrawn,
      traded,
      orders,
    }),
    [accountValueDisplay, profitDisplay, percentageTicker, portfolio, traded, orders, deposited, withdrawn],
  );

  return output;
};
