import React, { createContext, useContext, useEffect, useMemo, PropsWithChildren } from 'react';

import { api } from '@shared/api';
import env from '@shared/config';
import { UIStore } from '@shared/store';

import { useEntityOnboardingApplication } from '@hooks/Entity/useEntityOnboardingApplication';

import { useInterpret, useSelector } from '@xstate/react';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { assign, InterpreterFrom, Subscribable } from 'xstate';

import { entityOnboardingMachine } from './EntityOnboarding.machine';
import { useEntityOnboardingAnalytics } from './events/useEntityOnboardingAnalytics';
import { EntityType } from './types/EntityApplicationForm.types';
import { EntityOnboardingData, EntityOnboardingStepId } from './types/EntityOnboarding.types';
import { getFinishedApplicationStats } from './utils/getFinishedApplicationStats';
import { getFinishedSteps } from './utils/getFinishedSteps';
import { transformEntityOnboardingPayloadToEntityOnboardingData } from './utils/transforms';

export const EntityOnboardingContext = createContext<{
  entityOnboardingService: InterpreterFrom<typeof entityOnboardingMachine>;
}>({
  entityOnboardingService: {} as InterpreterFrom<typeof entityOnboardingMachine>,
});

const EntityOnboardingProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { navigate } = useNavigateRoute();
  const { stepCompleted } = useEntityOnboardingAnalytics();
  const { addToastMessage } = UIStore.useUIStore;
  const { updateApplyForEntityAccountFlag } = useEntityOnboardingApplication();
  const { applicationSubmitted } = useEntityOnboardingAnalytics();

  const entityOnboardingService = useInterpret(entityOnboardingMachine, {
    devTools: env.ENABLE_XSTATE_INSPECT,
    context: {
      applicationData: {},
      completedSteps: {},
    },
    actions: {
      prefillProfileData: assign((context, event) => {
        const prefilledEntityOnboardingData = transformEntityOnboardingPayloadToEntityOnboardingData(event.data);
        return {
          applicationData: {
            ...context.applicationData,
            ...prefilledEntityOnboardingData,
          },
          completedSteps: {
            ...context.completedSteps,
            ...getFinishedSteps(prefilledEntityOnboardingData),
          },
        };
      }),
      completeStep: assign((context, event) => {
        const entityType = context.applicationData.type;
        stepCompleted(event.stepId as EntityOnboardingStepId, { entityType });
        if (event.stepId === 'entityType' && context?.applicationData?.type?.toString().includes('SMSF')) {
          return {
            completedSteps: {
              ...context.completedSteps,
              [event.stepId]: true,
              trustInformation: true,
              companyInformation: true,
            },
          };
        }
        return {
          completedSteps: {
            ...context.completedSteps,
            [event.stepId]: true,
          },
        };
      }),
      redirectToDashboard: () => {
        navigate(NavigationURLs.Dashboard);
      },
      assignApplicationData: assign((context, event) => ({
        applicationData: {
          ...context.applicationData,
          ...event.data,
        },
      })),
      changeTrustType: assign((_, event) => {
        api.endpoints.saveEntityApplicationData({ data: { type: event.data.type } });
        return {
          completedSteps: {
            introduction: true,
            entityType: true,
          },
          applicationData: {
            type: event.data.type,
          },
        };
      }),
      changeEntityType: assign((_, event) => {
        api.endpoints.saveEntityApplicationData({ data: { type: event.data.type } });
        return {
          applicationData: {
            type: event.data.type,
          },
          completedSteps: {
            introduction: true,
            entityType: true,
            ...(event.data.type?.toString().includes('SMSF')
              ? { trustInformation: true, companyInformation: true }
              : {}),
          },
        };
      }),
      saveEntityApplicationFormData: (context, event) => {
        const contextData = context.applicationData;
        const eventData = event.data;
        const data: Partial<EntityOnboardingData> = { ...contextData, ...eventData };
        return api.endpoints.saveEntityApplicationData({ data });
      },
    },
    services: {
      fetchEntityOnboardingData: async () => {
        try {
          const response = await api.endpoints.getEntityApplicationForm();
          return response.data;
        } catch (e) {
          // We don't have any applications, lets try and create one
          await api.endpoints.createEntityApplication();
          const response = await api.endpoints.getEntityApplicationForm();
          if (response?.data) {
            return response.data;
          }
          return {};
        }
      },
      submitEntityApplicationData: async (context) => {
        try {
          const data = context.applicationData;
          const uploadedFilesResponse = await api.endpoints.getEntityDocuments();
          const applicationStats = getFinishedApplicationStats(data, uploadedFilesResponse.data.length ?? 0);

          const response = await api.endpoints.submitEntityApplicationForm({ data });

          if (data.type) {
            applicationSubmitted({
              entityType: data.type,
              numberOfDirectors: applicationStats?.numberOfDirectors,
              numberOfBeneficiaries: applicationStats?.numberOfBeneficiaries,
              numberOfTrustees: applicationStats?.numberOfTrustees,
              numberOfDocumentsUploaded: applicationStats?.numberOfDocumentsUploaded ?? 0,
              numberOfCorporateTrustees: applicationStats?.numberOfCorporateTrustees,
            });
          }
          updateApplyForEntityAccountFlag(false);
          return response.data;
        } catch (error) {
          addToastMessage({
            severity: 'error',
            message: 'There was an error submitting your entity application. Please try again or contact support',
          });
          entityOnboardingService.send({ type: 'SUBMIT_FAILED' });
        }
      },
    },
    guards: {
      hasChosenCompanyType: (context) => context.applicationData.type === EntityType.COMPANY,
      hasChosenTrustType: (context) =>
        ['TRUST', 'TRUST_CORPORATE', 'TRUST_INDIVIDUAL'].includes(context.applicationData.type ?? ''),
      hasChosenCorporateTrustType: (context) => context.applicationData.type === 'TRUST_CORPORATE',
      hasChosenIndividualTrustType: (context) => context.applicationData.type === 'TRUST_INDIVIDUAL',
      hasNotCompletedEntityTypeStep: (context) => !context.completedSteps.entityType,
      hasNotCompletedCompanyInformationStep: (context) => !context.completedSteps.companyInformation,
      hasNotCompletedCompanyAddDirectorsStep: (context) => !context.completedSteps['companyAddMembers.addDirectors'],
      hasNotCompletedCompanyAddShareholdersStep: (context) =>
        !context.completedSteps['companyAddMembers.addShareholders'],
      hasNotCompletedSmsfAddMembersStep: (context) => !context.completedSteps['smsfAddMembers.addIndividuals'],
      hasNotCompletedSmsfAddTrusteesStep: (context) => !context.completedSteps['smsfAddMembers.addCorporateMembers'],
      hasNotCompletedIndividualTrustAddTrusteesStep: (context) =>
        !context.completedSteps['individualTrustAddMembers.addTrustees'],
      hasNotCompletedIndividualTrustAddBeneficiariesStep: (context) =>
        !context.completedSteps['individualTrustAddMembers.addBeneficiaries'],
      hasNotCompletedCorporateTrustAddDirectorsStep: (context) =>
        !context.completedSteps['corporateTrustAddMembers.addDirectors'],
      hasNotCompletedCorporateTrustAddShareholdersStep: (context) =>
        !context.completedSteps['corporateTrustAddMembers.addShareholders'],
      hasNotCompletedCorporateTrustAddBeneficiariesStep: (context) =>
        !context.completedSteps['corporateTrustAddMembers.addBeneficiaries'],
    },
  });

  useEffect(() => {
    entityOnboardingService.onTransition((state, event) => {
      if (event.type === 'NEXT') {
        const stepId = state.history?.toStrings()?.slice(0, 2).slice(-1)[0] as EntityOnboardingStepId;
        if (stepId && !state.context.completedSteps[stepId]) {
          entityOnboardingService.send({ type: 'COMPLETE_STEP', stepId });
        }
      }
    });
  }, [entityOnboardingService]);

  const value = useMemo(
    () => ({
      entityOnboardingService,
    }),
    [entityOnboardingService],
  );

  return <EntityOnboardingContext.Provider value={value}>{children}</EntityOnboardingContext.Provider>;
};

EntityOnboardingProvider.displayName = 'EntityOnboardingProvider';

export { EntityOnboardingProvider };

export const useEntityOnboardingService = () => {
  const { entityOnboardingService } = useContext(EntityOnboardingContext);
  return entityOnboardingService;
};

export const useEntityOnboardingSelector = <
  T,
  TEmitted = InterpreterFrom<typeof entityOnboardingMachine> extends Subscribable<infer Emitted> ? Emitted : never,
>(
  selector: (emitted: TEmitted) => T,
) => {
  const entityOnboardingService = useEntityOnboardingService();
  const value = useSelector(entityOnboardingService, selector);

  return value;
};
