import React, { useCallback, useContext } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { MFAEnrolment } from '@shared/api';

import { LoginContext } from '@routes/Login/Login.context';
import { LoginStep } from '@routes/Login/types';

import { observer } from 'mobx-react-lite';

import { LoginTitle } from '../LoginTitle';

const TwoFactorEnrolment: React.FC = observer(() => {
  const { mfaData, setLoginStep } = useContext(LoginContext);

  const completeSetup = useCallback(async () => {
    if (mfaData) window.open((mfaData as MFAEnrolment).mfa_enrollment, '_blank', 'noopener,noreferrer');

    setLoginStep(LoginStep.SignIn);
  }, [mfaData]);

  return (
    <FlexLayout direction='column' spacing={32}>
      <LoginTitle id='twoFactorEnrolment.labels.title' title='2FA Enrolment Required' />
      <Body id='twoFactorEnrolment.labels.subTitleDescription' className='mb-24 w-full' color='primary'>
        You have not completed your two factor authentication (2FA) enrolment yet. You will need to complete this before
        logging in.
      </Body>
      <Button
        id='twoFactorEnrolment.buttons.completeSetup'
        variant='filled'
        size='lg'
        className='mt-24 w-full'
        onClick={completeSetup}
      >
        Complete Setup
      </Button>
    </FlexLayout>
  );
});

TwoFactorEnrolment.displayName = 'TwoFactorEnrolment';

export { TwoFactorEnrolment };
