import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box, CircularProgress, TableHead } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MUITableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { Input, Typography, Notification } from '@swyftx/react-web-design-system';

import { FiatCodeEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { UIStore, UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';
import { CardDepositProvider } from '@hooks/Deposit/useCardDepositProvider';

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError, StripePaymentElementChangeEvent } from '@stripe/stripe-js';

interface StripeCardFormProps {
  setOnSubmit: (onSubmit: () => void) => void;
  setIsSubmitDisabled: (isSubmitDisabled: boolean) => void;
  onSuccess?: () => void;
  onFailure?: () => void;
  amount: Big;
  feeAmount: Big;
  feePercentage: Big;
}

const TableCell = (props: any) => <MUITableCell padding='none' sx={{ border: 'none' }} {...props} />;

export const StripeCardForm = ({
  setOnSubmit,
  setIsSubmitDisabled,
  onSuccess,
  onFailure,
  amount,
  feeAmount,
  feePercentage,
}: StripeCardFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const { addToastMessage } = UIStore.useUIStore;
  const [cardholderName, setCardholderName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isStripeFormCompleted, setIsStripeFormCompleted] = useState(false);
  const { t } = useTranslation('wallet', { keyPrefix: 'depositInformation.stripe.cardForm' });
  const { pathname } = useLocation();
  const { userCountryCurrencyCode, userProfile } = UserStore.useUserStore;
  const avo = useAvo();

  const defaultCountry = useMemo(() => {
    switch (userCountryCurrencyCode) {
      case FiatCodeEnum.NZD:
        return 'NZ';
      case FiatCodeEnum.AUD:
        return 'AU';
      default:
        // rely on stripe browser country detection
        return undefined;
    }
  }, [userCountryCurrencyCode]);

  const showErrorMessage = (error: StripeError) => {
    avo.cardDepositFailed({
      screen: pathname,
      depositOption: CardDepositProvider.Stripe,
      amount: amount.toNumber(),
      currency: userCountryCurrencyCode,
    });

    let errorMessage = t('errorCodes.genericFailure');

    if (error.type === 'card_error' && error.message) {
      switch (error.code) {
        case 'card_declined':
          errorMessage = t('errorCodes.card_declined');
          break;
        case 'balance_insufficient':
          errorMessage = t('errorCodes.balance_insufficient');
          break;
        case 'insufficient_funds':
          errorMessage = t('errorCodes.insufficient_funds');
          break;
        case 'blocked':
          errorMessage = t('errorCodes.blocked');
          break;
        default:
          break;
      }
    } else if (error.type === 'invalid_request_error' && error.message?.includes('American Express')) {
      errorMessage = t('errorCodes.americanExpress');
    }

    addToastMessage({
      severity: 'error',
      message: t('depositUnsuccessful', { errorMessage }),
    });
  };

  const handleSubmit = useCallback(async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsSubmitDisabled(true);

    const payment = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: cardholderName,
            email: userProfile?.email,
            phone: userProfile?.phone,
            address: {
              country: defaultCountry,
            },
          },
        },
      },
      // This will prevent redirection if its not necessary and let us handle success flows
      redirect: 'if_required',
    });

    const { paymentIntent, error } = payment;

    if (error) {
      showErrorMessage(error);
    } else if ((paymentIntent && paymentIntent.status === 'succeeded') || paymentIntent.status === 'requires_capture') {
      // https://stripe.com/docs/payments/payment-intents/verifying-status#checking-status
      // Seems like it is safe to assume for card payments, any status other than 'succeeded' means failure.
      avo.cardDepositSuccessful({
        screen: pathname,
        depositOption: CardDepositProvider.Stripe,
        amount: amount.toNumber(),
        currency: userCountryCurrencyCode,
      });
      addToastMessage({
        severity: 'success',
        message: t('depositSuccessful'),
      });

      if (onSuccess) {
        onSuccess();
      }
    } else {
      avo.cardDepositFailed({
        screen: pathname,
        depositOption: CardDepositProvider.Stripe,
        amount: amount.toNumber(),
        currency: userCountryCurrencyCode,
      });
      addToastMessage({
        severity: 'error',
        message: t('depositUnsuccessful', { errorMessage: t('errorCodes.genericFailure') }),
      });

      if (onFailure) {
        onFailure();
      }
    }
    setIsSubmitDisabled(false);
  }, [avo, elements, stripe, cardholderName, userProfile?.email, userProfile?.phone]);

  useEffect(() => {
    setOnSubmit(() => handleSubmit);
  }, [handleSubmit, setOnSubmit]);

  useEffect(() => {
    setIsSubmitDisabled(!isStripeFormCompleted || !cardholderName);
    if (isStripeFormCompleted && cardholderName) {
      avo.confirmedCardDetails({
        screen: pathname,
        depositOption: CardDepositProvider.Stripe,
      });
    }
  }, [avo, isStripeFormCompleted, cardholderName, setIsSubmitDisabled]);

  useEffect(() => {
    const paymentElement = elements?.getElement('payment');

    if (!paymentElement) {
      return;
    }

    paymentElement.on('change', (event: StripePaymentElementChangeEvent) => {
      setIsStripeFormCompleted(event.complete);
    });

    paymentElement.on('ready', () => {
      setIsLoading(false);
    });
  }, [elements]);

  return (
    <>
      {isLoading && (
        <Box width='100%' height='380px' display='flex' alignItems='center' justifyContent='center'>
          <CircularProgress />
        </Box>
      )}

      <Box display={isLoading ? 'none' : 'block'}>
        <Notification
          severity='info'
          sx={{
            marginTop: 2,
            marginBottom: 2,
            '.MuiAlertTitle-root': {
              fontSize: 13.7,
              fontWeight: 600,
              color: 'primary.main',
            },
          }}
          title={t('sameNameTitle')}
        >
          {t('sameNameDescription')}
        </Notification>
        <label
          style={{
            fontWeight: 600,
            fontSize: '14px',
            fontFamily: 'Public Sans',
          }}
        >
          {t('cardHolderName')}
        </label>
        <Input
          hiddenLabel
          inputProps={{
            sx: {
              fontFamily: 'Public Sans',
              fontSize: '16px',
              padding: '15px',
            },
          }}
          placeholder={t('cardHolderNamePlaceholder')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCardholderName(e.target.value);
          }}
        />
        <br />
        <PaymentElement
          id='payment-element'
          options={{
            wallets: {
              googlePay: 'never',
              applePay: 'never',
            },
            fields: {
              billingDetails: {
                address: {
                  country: 'never',
                },
              },
            },
            defaultValues: {
              billingDetails: {
                address: {
                  country: defaultCountry,
                },
              },
            },
          }}
        />
        <Box mt={1}>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontWeight={600}>Deposit summary</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component='th' scope='row' padding='none'>
                    Amount
                  </TableCell>
                  <TableCell align='right' padding='none'>
                    <Typography number color='text.main'>
                      ${amount.toNumber()}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row' padding='none'>
                    {feePercentage.toFixed(2)}% Fee
                  </TableCell>
                  <TableCell align='right'>
                    <Typography number color='text.main'>
                      ${feeAmount.toFixed(2)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Amount to receive
                  </TableCell>
                  <TableCell align='right'>
                    <Typography number fontWeight='bold'>
                      ${amount.minus(feeAmount).toFixed(2)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};
