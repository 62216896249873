import {
  api,
  AddressDetailsKeyEnum,
  AddWithdrawAddressResponse,
  Asset,
  DepositAddressDetails,
  Network,
  WithdrawAddressResponse,
  WithdrawalAddressDetails,
  WithdrawalAddressDetailsOptionalKeyEnum,
  WithdrawalPermissionsResponse,
  AssetType,
} from '@shared/api';
import { ASSET_DEFAULTS } from '@shared/constants';
import { BankNameEnum, FiatIdEnum, MiningFeeEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { truncateNumber } from '@shared/utils';

export interface WithdrawAddressMetadata {
  name: string;
  data: string;
}

export interface WithdrawalLimit {
  used: Big;
  remaining: Big;
  limit: Big;
  reducedForPromisedDeposits: boolean;
  rollingCycleHrs: number;
}

export interface WithdrawalAddress extends WithdrawAddressResponse {
  metadata?: WithdrawAddressMetadata;
}

// DO NOT shuffle the values that the ordering should match the WithdrawalReasonSurvey enum on the backend!
export enum WithdrawalReasonEnum {
  // PayGoods = 1,
  PayRansomware = 2,
  TransferToWallet = 3,
  TransferToPlatform = 4,
  TradeOnOtherExchange = 5,
  UnhappyWithSwyftx = 6,
  // SendToFriend = 7,
  TransferToBank = 8,
}

export interface WithdrawalReason {
  key: WithdrawalReasonEnum;
  label: string;
  alias: string;
}

const withdrawReason: {
  [reason in WithdrawalReasonEnum]: WithdrawalReason;
} = {
  // [WithdrawalReasonEnum.PayGoods]: {
  //   key: WithdrawalReasonEnum.PayGoods,
  //   label: 'withdrawalReason.payForGoods',
  //   alias: 'PAYING_FOR_GOODS_OR_SERVICES',
  // },
  [WithdrawalReasonEnum.PayRansomware]: {
    key: WithdrawalReasonEnum.PayRansomware,
    label: 'withdrawalReason.payForRansomware',
    alias: 'PAYING_FOR_RANSOMWARE',
  },
  [WithdrawalReasonEnum.TransferToWallet]: {
    key: WithdrawalReasonEnum.TransferToWallet,
    label: 'withdrawalReason.transferToPersonalWallet',
    alias: 'TRANSFER_TO_PERSONAL_WALLET',
  },
  [WithdrawalReasonEnum.TransferToPlatform]: {
    key: WithdrawalReasonEnum.TransferToPlatform,
    label: 'withdrawalReason.transferToInterestPlatform',
    alias: 'TRANSFER_TO_CUSTODY_PLATFORM',
  },
  [WithdrawalReasonEnum.TradeOnOtherExchange]: {
    key: WithdrawalReasonEnum.TradeOnOtherExchange,
    label: 'withdrawalReason.tradingOnAnotherExchange',
    alias: 'TRADING_ON_ANOTHER_EXCHANGE',
  },
  [WithdrawalReasonEnum.UnhappyWithSwyftx]: {
    key: WithdrawalReasonEnum.UnhappyWithSwyftx,
    label: 'withdrawalReason.unhappyWithSwyftx',
    alias: 'NOT_HAPPY_WITH_SWYFTX',
  },
  // [WithdrawalReasonEnum.SendToFriend]: {
  //   key: WithdrawalReasonEnum.SendToFriend,
  //   label: 'withdrawalReason.sendToFriend',
  //   alias: 'SENDING_TO_A_FRIEND',
  // },
  [WithdrawalReasonEnum.TransferToBank]: {
    key: WithdrawalReasonEnum.TransferToBank,
    label: 'withdrawalReason.transferToBank',
    alias: 'TRANSFER_TO_MY_BANK_ACCOUNT',
  },
};

/* Return withdrawal reasons in random order but having 'Pay Ransomware' appended as last option in the list */
const getSortedWithdrawalReasons = (): WithdrawalReason[] => {
  const reasons: WithdrawalReason[] = Object.values(withdrawReason)
    .filter((reason: WithdrawalReason) => reason.key !== WithdrawalReasonEnum.PayRansomware)
    .sort(() => Math.random() - 0.5);
  reasons.push(withdrawReason[WithdrawalReasonEnum.PayRansomware]);
  return reasons;
};

const withdrawalAddressMetadataKeyNamePair = [
  { name: 'Memo', key: WithdrawalAddressDetailsOptionalKeyEnum.Memo },
  { name: 'Destination Tag', key: WithdrawalAddressDetailsOptionalKeyEnum.DestinationTag },
  { name: 'Message', key: WithdrawalAddressDetailsOptionalKeyEnum.Message },
  { name: 'Payment ID', key: WithdrawalAddressDetailsOptionalKeyEnum.PaymentId },
  { name: 'BSB', key: AddressDetailsKeyEnum.BSB, required: true },
];

export const getWithdrawAddressMetadata = (
  addressData: DepositAddressDetails | WithdrawalAddressDetails,
): WithdrawAddressMetadata => {
  const result = { name: '', data: '' };

  for (let i = 0; i < withdrawalAddressMetadataKeyNamePair.length; i++) {
    if (addressData[withdrawalAddressMetadataKeyNamePair[i].key as AddressDetailsKeyEnum]) {
      result.name = withdrawalAddressMetadataKeyNamePair[i].name;
      result.data = addressData[withdrawalAddressMetadataKeyNamePair[i].key as AddressDetailsKeyEnum];
      break;
    }
  }

  return result;
};

/*
 * Verify BSB number and return the combination of bank name and bank code on success
 */
const verifyBSB = async (bsb: string): Promise<{ bankName: string } | { errorMessage: string }> => {
  try {
    const results = await api.endpoints.verifyBsbForWithdraw({ params: { bsb } });
    const bankName = BankNameEnum[results.data.bankCode as keyof typeof BankNameEnum]
      ? `${BankNameEnum[results.data.bankCode as keyof typeof BankNameEnum]} (${results.data.bankCode})`
      : results.data.bankCode;
    return { bankName };
  } catch (error: any) {
    return { errorMessage: error.errorMessage };
  }
};

function formatNZAddress(phoneNumberString: string) {
  const match = phoneNumberString.match(/^(\d{2})(\d{4})(\d{7})(0?\d{2})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}-${match[4]}`;
  }
  throw new Error(`Failed to format NZ address. Invalid address provided`);
}

const addWithdrawAddress = async (
  code: string,
  label: string,
  address: string,
  nameOnAccount: string,
  bsb: string,
  authToken?: string,
  pin?: string,
): Promise<AddWithdrawAddressResponse> => {
  const formattedAddress = code.toUpperCase() === 'NZD' ? formatNZAddress(address) : address;
  const auth = authToken && pin ? `${authToken} ${pin}` : undefined;
  const results = await api.endpoints.addWithdrawAddress({
    params: { code },
    data: {
      address: {
        label,
        address_details: {
          address: formattedAddress,
          nameOnAccount,
          bsb,
        },
      },
      auth,
    },
  });

  return results?.data;
};

const addCryptoWithdrawAddress = async (
  code: string,
  label: string,
  address: string,
  networkId?: number,
  metadata?: Record<string, string> | undefined,
  authToken?: string,
  pin?: string,
): Promise<AddWithdrawAddressResponse> => {
  const auth = authToken && pin ? `${authToken} ${pin}` : undefined;
  const results = await api.endpoints.addWithdrawAddress({
    params: { code },
    data: {
      address: {
        label,
        address_details: {
          address,
          networkId,
          ...metadata,
        },
      },
      auth,
    },
  });

  return results?.data;
};

const getWithdrawalLimit = async (): Promise<WithdrawalLimit> => {
  const results = await api.endpoints.getWithdrawalLimit();
  const { limits } = results.data;
  const withdrawalLimit: WithdrawalLimit = {
    ...limits,
    limit: Big(limits.limit),
    used: Big(limits.used),
    remaining: Big(limits.remaining),
  };
  return withdrawalLimit;
};

const getWithdrawalPermissions = async (assetCode: string): Promise<WithdrawalPermissionsResponse> => {
  const results = await api.endpoints.getWithdrawalPermissions({
    params: { assetCode },
  });
  return results.data;
};

const getWithdrawAddresses = async (code: string): Promise<WithdrawalAddress[] | []> => {
  const results = await api.endpoints.getWithdrawAddresses({ params: { code } });

  for (const address of results.data.address as WithdrawalAddress[]) {
    const addrMetadata = withdrawalService.getWithdrawAddressMetadata(address.address_details);
    if (addrMetadata) address.metadata = addrMetadata;
  }

  return (results?.data?.address as WithdrawalAddress[]) ?? [];
};

const removeWithdrawalAddress = async (addressId: number, code: string): Promise<void> => {
  await api.endpoints.removeWithdrawAddress({ params: { id: addressId, code } });
};

const getAddressAssetNetwork = (address: WithdrawalAddress, asset: Asset): Network | undefined =>
  asset.networks.find((network) => network.networkName === address.address_details.network);

const getMiningFee = (address: WithdrawalAddress, asset: Asset): number => {
  if (FiatIdEnum.AUD === asset.id) {
    return MiningFeeEnum.AUD;
  }

  const assetNetwork = getAddressAssetNetwork(address, asset);
  return assetNetwork?.withdrawFee ?? ASSET_DEFAULTS.miningFee;
};

const getMinWithdrawal = (address: WithdrawalAddress, asset: Asset): number => {
  const assetNetwork = getAddressAssetNetwork(address, asset);
  return assetNetwork?.withdrawMin ?? asset.min_withdrawal;
};

const getMinWithdrawalIncrementE = (address: WithdrawalAddress, asset: Asset): number => {
  const assetNetwork = getAddressAssetNetwork(address, asset);
  if (assetNetwork?.minWithdrawalIncrementE != null) return assetNetwork.minWithdrawalIncrementE;
  return asset.minWithdrawalIncrementE;
};

const getWithdrawAmount = (address: WithdrawalAddress, asset: Asset, currencyAmount: Big): string => {
  const minWithdrawalIncrementE = getMinWithdrawalIncrementE(address, asset);
  return truncateNumber(currencyAmount, minWithdrawalIncrementE);
};

const canNetworkWithdraw = (address: WithdrawalAddress, asset: Asset): boolean => {
  if (asset.assetType === AssetType.Fiat) return true;

  const network = asset.networks.find((n) => n.networkName === address.address_details.network);
  if (!network) return true;
  return !network.withdrawDisableForce && !network.withdrawDisabled;
};

export const withdrawalService = {
  withdrawalAddressMetadataKeyNamePair,
  getWithdrawAddressMetadata,
  verifyBSB,
  addWithdrawAddress,
  getWithdrawalLimit,
  getWithdrawalPermissions,
  getWithdrawAddresses,
  removeWithdrawalAddress,
  getMiningFee,
  getMinWithdrawal,
  getWithdrawAmount,
  getMinWithdrawalIncrementE,
  addCryptoWithdrawAddress,
  getSortedWithdrawalReasons,
  getAddressAssetNetwork,
  canNetworkWithdraw,
};
