import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Loading } from '@swyftx/aviary/icons/outlined';
import { Stack } from '@swyftx/react-web-design-system';

import { UserStore } from '@shared/store';

import { RewardsContext } from '@routes/Rewards/Rewards.context';
import { RewardCard } from '@routes/Rewards/components';
import { RewardsWrapper } from '@routes/Rewards/components/RewardsWrapper';

import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { useInterval } from 'react-use';

import { EmptyRewards } from './components/EmptyRewards/EmptyRewards';
import { RewardType } from './types';

const REWARDS_FETCH_INTERVAL = 60_000;

const Rewards: React.FC = observer(() => {
  const [rewardType, setRewardType] = useState<RewardType>(RewardType.Available);
  const { rewards, fetchingRewards, fetchRewards, viewRewards } = useContext(RewardsContext);
  const { userProfile } = UserStore.useUserStore;
  const location = useLocation();
  const mounted = useRef(false);

  const hasNoRewards = useMemo(() => !rewards[rewardType].length, [rewardType, rewards, rewards[rewardType].length]);

  useInterval(() => {
    if (userProfile?.intercom?.uuid) {
      fetchRewards(userProfile.intercom.uuid, RewardType.Available);
    }
  }, REWARDS_FETCH_INTERVAL);

  useEffect(() => {
    viewRewards();
  }, [viewRewards]);

  useEffect(() => {
    if (userProfile?.intercom?.uuid) {
      // Fetch available and history once on mount
      if (!mounted.current) {
        fetchRewards(userProfile.intercom.uuid, RewardType.Available);
        fetchRewards(userProfile.intercom.uuid, RewardType.History);
        mounted.current = true;
      } else {
        // Refresh on tab switch
        fetchRewards(userProfile.intercom.uuid, rewardType);
      }
    }
  }, [userProfile?.intercom?.uuid, rewardType]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!rewards.available || !rewards.history) return null;

  return (
    <RewardsWrapper
      rewardType={rewardType}
      setRewardType={setRewardType}
      rewards={rewards}
      showLearnMore={location?.state?.showLearnMore || false}
    >
      <Stack width='100%' spacing={2}>
        {rewards[rewardType].map((reward) => (
          <RewardCard key={reward.id} reward={reward} />
        ))}
        {hasNoRewards && (
          <>
            {fetchingRewards ? (
              <FlexLayout className='mt-16 w-full' alignItems='center' justifyContent='center'>
                <Loading className='animate-spin text-color-text-primary' />
              </FlexLayout>
            ) : (
              <EmptyRewards rewardType={rewardType} />
            )}
          </>
        )}
      </Stack>
    </RewardsWrapper>
  );
});

export { Rewards };
