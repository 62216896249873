import { PortfolioHistoryRecord } from '@shared/api/@types/history';

import { HistoryResult, OrderHistoryResponse } from '../../@types/portfolio';
import { Methods, request } from '../rest';

const portfolioEndpoints = {
  getTradePriceHistory: ({
    params,
    headers,
  }: {
    params: { denotedAssetId: number };
    headers?: Record<string, string>;
  }) =>
    request({
      path: '/portfolio/tradePriceHistory/:denotedAssetId/',
      method: Methods.GET,
      headers,
      params,
      auth: true,
      demoable: false,
    }),

  getAssetTransactionHistory: ({
    params,
    headers,
    query,
  }: {
    params: any; // AssetTransactionHistoryParams
    query: any; // AssetTransactionHistoryParams
    headers?: Record<string, string>;
  }): HistoryResult =>
    request({
      path: '/portfolio/assetHistory/:assetId/',
      method: Methods.GET,
      headers,
      query,
      params,
      auth: true,
      demoable: true,
    }),

  getAllTransactionHistory: ({
    query,
  }: {
    query: any; // AssetTransactionHistoryParams
  }): HistoryResult =>
    request({
      path: '/portfolio/assetHistory/all/',
      method: Methods.GET,
      query,
      auth: true,
      demoable: true,
    }),

  cancelOrder: ({ params, headers }: { params: { orderId: string }; headers?: Record<string, string> }) =>
    request({
      path: '/orders/:orderId/',
      method: Methods.DELETE,
      headers,
      params,
      auth: true,
      demoable: true,
    }),

  getAssetCostBasis: ({ params }: { params: { assetId?: string } }): PortfolioHistoryRecord[] =>
    request({
      path: '/portfolio/costBasis/:assetId/',
      method: Methods.GET,
      params,
      auth: true,
    }),

  getPortfolioCostBasis: (): PortfolioHistoryRecord[] =>
    request({
      path: '/portfolio/costBasis/',
      method: Methods.GET,
      auth: true,
    }),

  getOrdersHistory: ({
    query,
  }: {
    query: {
      from: number;
      to: number;
      limit: number;
    };
  }): OrderHistoryResponse =>
    request({
      path: `/orders/?allOrders=true`,
      method: Methods.GET,
      auth: true,
      query,
      demoable: true,
    }),
};

export default portfolioEndpoints;
