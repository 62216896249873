import { Individual } from '@shared/api';

export enum EntityType {
  COMPANY = 'COMPANY',
  SMSF = 'SMSF',
  TRUST = 'TRUST',
}

export enum HumanReadablePurposeOfAccount {
  'DAY_TRADING' = 'Day trading',
  'INVESTMENT' = 'Investment',
  'PAYING_FOR_RANSOMWARE' = 'Paying for ransomware',
  // 'PURCHASING_WITH_CRYPTO' = 'Purchasing goods or services with crypto',
  // 'TRANSFERRING_MONEY' = 'Transferring money',
  'RAMPING_CRYPTO' = 'On-ramping/off-ramping crypto',
  'RECREATIONAL_TRADING' = 'Recreational trading',
  'RESEARCH_TESTING' = 'Research/testing',
  // 'SENDING_FUNDS_EXTERNALLY' = 'Sending funds externally',
  'STAKING' = 'Staking',
}

export enum HumanReadableSourceOfWealthOption {
  OCCUPATIONAL_INCOME = 'Occupational income',
  INVESTMENT_INCOME = 'Investment income',
  RENTAL_INCOME = 'Rental income',
  BUSINESS_OWNERSHIP = 'Business ownership',
  INTELLECTUAL_PROPERTY = 'Intellectual property',
  SAVINGS = 'Savings',
  INHERITANCE = 'Inheritance',
  GAMBLING_OR_LOTTERY = 'Gambling or lottery',
  SELF_MANAGED_SUPER_FUND = 'Self-Managed Superannuation Fund',
}

export enum HumanReadableEntityTypes {
  COMPANY = 'Company',
  SMSF = 'Self Managed Super Fund (SMSF)',
  SMSF_INDIVIDUAL = 'Self Managed Super Fund (SMSF)',
  SMSF_CORPORATE = 'Self Managed Super Fund (SMSF)',
  TRUST = 'Trust',
  TRUST_INDIVIDUAL = 'Individual Trust',
  TRUST_CORPORATE = 'Corporate Trust',
}

export type MemberType = 'director' | 'shareholder' | 'member' | 'trustee' | 'beneficiary';

export type IndividualWithUuid = { uuid: string; individual: Individual };
