export enum AccountPurpose {
  INVESTMENT = 'investment',
  // TRANSFERRING_MONEY = 'transferring_money',
  // PURCHASING_WITH_CRYPTO = 'purchasing_goods_or_services_with_crypto',
  // SENDING_FUNDS_EXTERNALLY = 'sending_funds_externally',
  RAMPING_CRYPTO = 'on-ramping_off-ramping_crypto',
  DAY_TRADING = 'day-trading',
  PAYING_FOR_RANSOMWARE = 'paying_for_ransomware',
  RECREATIONAL_TRADING = 'recreational_trading',
  RESEARCH_TESTING = 'research_testing',
}
