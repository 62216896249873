import { api } from '@shared/api';
import { UserStore } from '@shared/store';

import { useAsync } from 'react-use';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

export const useGetSourceOfWealthStatus = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { getUniqueIdentifier } = UserStore.useUserStore;

  const fetchSourceOfWealthHistory = async () => {
    if (!isFeatureEnabled(AppFeature.SourceOfWealth)) return undefined;
    const response = await api.endpoints.getSourceOfWealthHistory();
    return response.data;
  };

  const fetchSourceOfWealthStatus = async () => {
    if (!isFeatureEnabled(AppFeature.SourceOfWealth)) return undefined;
    const response = await api.endpoints.getSourceOfWealthStatus();
    return response.data;
  };

  const { loading: historyLoading, value: sourceOfWealthHistory } = useAsync(fetchSourceOfWealthHistory, [
    isFeatureEnabled,
    getUniqueIdentifier(), // account switched should trigger a re-fetch
  ]);

  const { loading: statusLoading, value: status } = useAsync(fetchSourceOfWealthStatus, [
    isFeatureEnabled,
    getUniqueIdentifier(), // account switched should trigger a re-fetch
  ]);

  return {
    loading: historyLoading || statusLoading,
    status,
    verification: sourceOfWealthHistory?.length ? sourceOfWealthHistory[0] : undefined,
  };
};
