import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { api } from '@shared/api';
import { SourceOfWealthStatus } from '@shared/api/@types/compliance';
import { UserStore } from '@shared/store';

import { useSourceOfWealthAnalytics } from '@routes/SourceOfWealth/useSourceOfWealthAnalytics';

import { observer } from 'mobx-react-lite';
import { useAsync } from 'react-use';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { NavigationURLs } from 'src/lib/navigation/types';

export const SourceOfWealthVerificationAction: React.FC = observer(() => {
  const navigate = useNavigate();
  const { startedVerification } = useSourceOfWealthAnalytics();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { getUniqueIdentifier } = UserStore.useUserStore;

  const fetchSourceOfWealthStatus = async () => {
    if (!isFeatureEnabled(AppFeature.SourceOfWealth)) return null;
    const response = await api.endpoints.getSourceOfWealthStatus();
    return response.data;
  };

  // account switched should trigger a re-fetch
  const { value: sourceOfWealthStatus } = useAsync(fetchSourceOfWealthStatus, [
    isFeatureEnabled,
    getUniqueIdentifier(),
  ]);

  const action = useMemo(() => {
    const navigateToSOW = () => {
      startedVerification();
      navigate(NavigationURLs.SourceOfWealth);
    };

    switch (sourceOfWealthStatus) {
      case SourceOfWealthStatus.REQUIRED:
      case SourceOfWealthStatus.REQUESTED:
        return (
          <>
            <Chip size='sm' color='destructive' variant='subtle'>
              Required
            </Chip>
            <Button onClick={navigateToSOW}>Verify now</Button>
          </>
        );
      case SourceOfWealthStatus.PASSED:
        return (
          <Chip size='sm' color='success' variant='subtle'>
            Verified
          </Chip>
        );
      case SourceOfWealthStatus.SUBMITTED:
      case SourceOfWealthStatus.MORE_INFO_SUBMITTED:
        return (
          <>
            <Chip size='sm' color='primary' variant='subtle'>
              Pending
            </Chip>
            <Button disabled>Verify now</Button>
          </>
        );
      case SourceOfWealthStatus.FAILED:
        return (
          <Chip size='sm' color='destructive' variant='subtle'>
            Failed
          </Chip>
        );
      case SourceOfWealthStatus.MORE_INFO_REQUESTED:
        return (
          <>
            <Chip size='sm' color='primary' variant='subtle'>
              Action required
            </Chip>
            <Button onClick={navigateToSOW}>Complete now</Button>
          </>
        );
      default:
        return (
          <Chip size='sm' color='secondary' variant='subtle'>
            Not required
          </Chip>
        );
    }
  }, [navigate, sourceOfWealthStatus, startedVerification]);

  return (
    <FlexLayout direction='row' className='flex items-center' spacing={8}>
      {action}
    </FlexLayout>
  );
});
